import React, { useContext, useState } from "react";
import {
    Card,
    Typography,
    CardBody,
    CardFooter,
    Input,
    Textarea,
    Button,
    IconButton,
    Dialog,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import * as yup from "yup";
import AxiosClient from "../../utils/axios";
import { AuthContext } from "../auth/authContext";
import Alert, { errorMsj, errorTitle, succesMsj, successTitle } from "../../utils/alert";

export default function SalesForm({ isOpen, data, onClose }) {
    const { user } = useContext(AuthContext)
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        formik.handleReset();
        setImages([]);
        onClose();
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const newImages = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (e) => {
                newImages.push({
                    fileBase64: e.target.result,
                    mimeType: file.type
                });
                if (newImages.length === files.length) {
                    setImages((prevImages) => [...prevImages, ...newImages]);
                    formik.setFieldValue('images', [...formik.values.images, ...newImages]);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        formik.setFieldValue('images', formik.values.images.filter((_, i) => i !== index));
    };

    const formik = useFormik({
        initialValues: {
            tittle: "",
            description: "",
            price: 0,
            location: "",
            areaTotalSquareMeter: null,
            constructionSquareMeter: null,
            images: [],
            presale: false,
            cantBedrooms: null,
            cantBathRooms: null,
            pool: false,
            status: false
        },
        validationSchema: yup.object().shape({
            tittle: yup.string().required("Campo obligatorio"),
            description: yup.string().required("Campo obligatorio"),
            price: yup.number().required("Campo obligatorio").min(0, "El precio no puede ser negativo"),
            location: yup.string().required("Campo obligatorio"),
            areaTotalSquareMeter: yup.number().required("Campo obligatorio").min(0, "El área total no puede ser negativa"),
            constructionSquareMeter: yup.number().required("Campo obligatorio").min(0, "El área de construcción no puede ser negativa"),
            cantBedrooms: yup.number().required("Campo obligatorio").min(0, "La cantidad de dormitorios no puede ser negativa"),
            cantBathRooms: yup.number().required("Campo obligatorio").min(0, "La cantidad de baños no puede ser negativa"),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const response = await AxiosClient({
                    url: "/house/save/",
                    method: "POST",
                    headers: { Authorization: `Bearer ${user.token}` },
                    data: JSON.stringify(values),
                });
                if (!response.error) {
                    Alert.fire({
                        title: successTitle,
                        text: succesMsj,
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Aceptar",
                    })
                }
            } catch (err) {
                Alert.fire({
                    title: errorTitle,
                    text: errorMsj,
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                })
            } finally {
                setIsLoading(false);
                data();
                handleClose()
            }
        },
    });
    return (
        <Dialog open={isOpen} handler={handleClose} size="lg" className="max-w-full h-[80vh]">
            <form onSubmit={formik.handleSubmit} className="h-full flex flex-col">
                <div className="flex items-center justify-between p-4">
                    <Typography variant="h5" color="blue-gray">
                        Subir propiedad
                    </Typography>
                    <IconButton variant="text" color="blue-gray" onClick={handleClose}>
                        <span className="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </IconButton>
                </div>
                <DialogBody divider className="flex-1 overflow-y-auto p-4">
                    <Card className="w-full max-w-4xl mx-auto">
                        <CardBody className="grid grid-cols-2 gap-6">
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Título</Typography>
                                <Input
                                    id="tittle"
                                    name="tittle"
                                    placeholder="Título de la propiedad"
                                    onChange={formik.handleChange}
                                    value={formik.values.tittle}
                                    error={formik.touched.tittle && Boolean(formik.errors.tittle)}
                                />
                                {formik.touched.tittle && formik.errors.tittle && (
                                    <Typography variant="small" color="red">{formik.errors.tittle}</Typography>
                                )}
                            </div>
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Descripción</Typography>
                                <Textarea
                                    id="description"
                                    name="description"
                                    placeholder="Describe la propiedad"
                                    className="min-h-[100px]"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <Typography variant="small" color="red">{formik.errors.description}</Typography>
                                )}
                            </div>
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Ubicación</Typography>
                                <Input
                                    id="location"
                                    name="location"
                                    placeholder="Ubicación de la propiedad"
                                    onChange={formik.handleChange}
                                    value={formik.values.location}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                />
                                {formik.touched.location && formik.errors.location && (
                                    <Typography variant="small" color="red">{formik.errors.location}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Precio</Typography>
                                <Input
                                    id="price"
                                    name="price"
                                    type="number"
                                    placeholder="Precio de la propiedad"
                                    onChange={formik.handleChange}
                                    value={formik.values.price}
                                    error={formik.touched.price && Boolean(formik.errors.price)}
                                />
                                {formik.touched.price && formik.errors.price && (
                                    <Typography variant="small" color="red">{formik.errors.price}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">PreVenta</Typography>
                                <input
                                    type="checkbox"
                                    id="presale"
                                    name="presale"
                                    checked={formik.values.presale}
                                    onChange={formik.handleChange}
                                    className="form-checkbox"
                                />
                                <span>&nbsp;Marcar si es preventa</span>
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Área Total (m²)</Typography>
                                <Input
                                    id="areaTotalSquareMeter"
                                    name="areaTotalSquareMeter"
                                    type="number"
                                    placeholder="Área total en metros cuadrados"
                                    onChange={formik.handleChange}
                                    value={formik.values.areaTotalSquareMeter}
                                    error={formik.touched.areaTotalSquareMeter && Boolean(formik.errors.areaTotalSquareMeter)}
                                />
                                {formik.touched.areaTotalSquareMeter && formik.errors.areaTotalSquareMeter && (
                                    <Typography variant="small" color="red">{formik.errors.areaTotalSquareMeter}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Área de Construcción (m²)</Typography>
                                <Input
                                    id="constructionSquareMeter"
                                    name="constructionSquareMeter"
                                    type="number"
                                    placeholder="Área de construcción en metros cuadrados"
                                    onChange={formik.handleChange}
                                    value={formik.values.constructionSquareMeter}
                                    error={formik.touched.constructionSquareMeter && Boolean(formik.errors.constructionSquareMeter)}
                                />
                                {formik.touched.constructionSquareMeter && formik.errors.constructionSquareMeter && (
                                    <Typography variant="small" color="red">{formik.errors.constructionSquareMeter}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Cantidad de Dormitorios</Typography>
                                <Input
                                    id="cantBedrooms"
                                    name="cantBedrooms"
                                    type="number"
                                    placeholder="Cantidad de dormitorios"
                                    onChange={formik.handleChange}
                                    value={formik.values.cantBedrooms}
                                    error={formik.touched.cantBedrooms && Boolean(formik.errors.cantBedrooms)}
                                />
                                {formik.touched.cantBedrooms && formik.errors.cantBedrooms && (
                                    <Typography variant="small" color="red">{formik.errors.cantBedrooms}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Cantidad de Baños</Typography>
                                <Input
                                    id="cantBathRooms"
                                    name="cantBathRooms"
                                    type="number"
                                    placeholder="Cantidad de baños"
                                    onChange={formik.handleChange}
                                    value={formik.values.cantBathRooms}
                                    error={formik.touched.cantBathRooms && Boolean(formik.errors.cantBathRooms)}
                                />
                                {formik.touched.cantBathRooms && formik.errors.cantBathRooms && (
                                    <Typography variant="small" color="red">{formik.errors.cantBathRooms}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Piscina</Typography>
                                <input
                                    type="checkbox"
                                    id="pool"
                                    name="pool"
                                    checked={formik.values.pool}
                                    onChange={formik.handleChange}
                                    className="form-checkbox"
                                />
                                <span>&nbsp;Marcar si tiene piscina</span>
                            </div>
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Imágenes</Typography>
                                <input
                                    type="file"
                                    id="images"
                                    name="images"
                                    multiple
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                <div className="flex flex-wrap gap-4 mt-4">
                                    {images.map((image, index) => (
                                        <div key={index} className="relative">
                                            <img src={image.fileBase64} alt="preview" className="w-32 h-32 object-cover rounded" />
                                            <IconButton
                                                variant="text"
                                                size="sm"
                                                className="absolute top-0 right-0"
                                                onClick={() => handleImageRemove(index)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </DialogBody>
                <DialogFooter>
                    <Button variant="outlined" onClick={handleClose} className="mr-2">Cancelar</Button>
                    {isLoading ? (
                        <Button variant="submit" loading={true}>
                            Subiendo...
                        </Button>
                    ) : (<Button type="submit" color="green">Subir</Button>
                    )}
                </DialogFooter>
            </form>
        </Dialog>

    );
}

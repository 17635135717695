import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "./authContext";
import { Card, CardBody, Typography, Input, Button, CardHeader, Alert } from "@material-tailwind/react";
import logo from '../../assets/tabsLogo.png';
import { useFormik } from "formik";
import * as yup from "yup";
import AxiosClient from "../../utils/axios";
import { Navigate } from "react-router";
import AlertComponent from "../../utils/AlertCustom";

const Login = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('info');

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: yup.object().shape({
      username: yup.string().required("Campo obligatorio"),
      password: yup.string().required("Campo obligatorio"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await AxiosClient({
          url: "/auth/login",
          method: "POST",
          data: JSON.stringify(values),
        });
        if (!response.error) {
          const action = {
            type: "LOGIN",
            payload: response.data,
          };
          dispatch(action);
        } else {
          setAlertMessage('Inicio se session errone, por favor revice sus credenciales');
          setAlertType('red')
          setAlertOpen(true);
        }
      } catch (err) {
        setAlertMessage('Ocurrio un error. Intentelo de nuevo');
        setAlertType('red')
        setAlertOpen(true);
        
      } finally {
        setIsLoading(false)
      }
    },
  })
  if (user.isLogged) {
    return <Navigate to={"/dashboard"} />;
  }
  return (
    <div className="flex items-center justify-center h-screen bg-primary">
      <Card className="rounded-lg border w-full max-w-md p-6 bg-card text-card-foreground shadow-lg">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 rounded-none flex justify-center"
        >
          <img
            className="h-20 w-20"
            src={logo}
            alt="card-image"
          />
        </CardHeader>
        <CardBody className="flex flex-col space-y-1.5 p-6">
          <Typography variant="h3" className="whitespace-nowrap tracking-tight text-2xl font-bold text-center">
            Bienvenido de nuevo
          </Typography>
          <Typography variant="small" className="text-sm text-muted-foreground text-center">
            Por favor, inicia sesión en tu cuenta.
          </Typography>
        </CardBody>
        <CardBody className="p-6 space-y-4">
          <div className="space-y-2">
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor="username"
            >
              Usuario
            </label>
            <Input
              className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              id="username"
              label="Usuario"

              placeholder="Ingresa tu usuario"
              value={formik.values.username}
              onChange={formik.handleChange}
            />
          </div>
          <div className="space-y-2 relative">
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor="password"
            >
              Contraseña
            </label>
            <Input
              label="contraseña"
              className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              id="password"
              placeholder="Ingresa tu contraseña"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex justify-center">
            <Button onClick={formik.handleSubmit} loading={isLoading} className="w-full mt-4">
              Iniciar
            </Button>
          </div>
        </CardBody>
      </Card>
      <AlertComponent
        type={alertType}
        message={alertMessage}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      />
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import { NavbarSimple } from '../../components/NavbarAdmin';
import ProjectScreen from '../project/ProjectScreen';
import { Button } from '@material-tailwind/react';
import SalesScreen from '../sales/SalesScreen';
import LandScreen from '../sales/land/LandScreen';


const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('project');

  const renderContent = () => {
    switch (activeTab) {
      case 'project':
        return <ProjectScreen />;
      case 'sales':
        return <SalesScreen />;
      case 'land':
        return <LandScreen />;
      default:
        return <ProjectScreen />;
    }
  };

  return (
    <div className="container mx-auto p-6">
      <NavbarSimple />
      <div className="my-4">
        <Button variant="gradient" className='m-2' onClick={() => setActiveTab('project')}>Proyectos</Button>
        <Button variant="gradient" className='m-2' onClick={() => setActiveTab('sales')}>CASAS</Button>
        <Button variant="gradient" className='m-2' onClick={() => setActiveTab('land')}>Terrenos</Button>
      </div>
      <div className="content" >
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;

import React from 'react';
import HeaderBar from '../shared/HeaderBar';

const Layout = ({ children }) => {
  return (
    <div>
      <HeaderBar />
      <main className='mt-16 lg:mt-0'>{children}</main>
    </div>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import { Dialog, DialogBody, DialogFooter, Button, Typography, Spinner, Carousel } from "@material-tailwind/react";
import AxiosClient from "../../utils/axios";
import LazyLoad from "react-lazyload";
import Skeleton from "./Skeleton"; // Importar el componente de Skeleton

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const projectsPerPage = 8;
  const [active, setActive] = useState("");

  const getProjects = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://tabsarq.mx:8443/project/get-status/true', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setProjects(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOneProject = async (uid) => {
    setIsDialogLoading(true);
    try {
      const response = await AxiosClient({
        url: `/project/get-one/${uid}`,
        method: "GET",
      });
      setSelectedProject(response.data);
      setActive(response.data.images[0].url);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDialogOpen(true);
      setIsDialogLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewMore = (uid) => {
    getOneProject(uid);
  };

  const handleDialogClose = () => {
    setActive("");
    setSelectedProject(null);
    setIsDialogOpen(false);
  };

  return (
    <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 md:px-6">
        <div className="text-center max-w-lg mx-auto space-y-4">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Portafolio de Proyectos</h2>
          <p className="max-w-md text-muted-foreground mx-auto md:text-xl lg:text-base xl:text-xl">
            Descubre nuestros trabajos más destacados y la calidad de nuestros servicios.
          </p>
        </div>
        <div className="mt-12 grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {isLoading ? (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
              <Spinner className="h-16 w-16 text-gray-900/50" />
            </div>
          ) : (
            currentProjects.map((project, index) => (
              <LazyLoad key={index} height={200} offset={100} placeholder={<Skeleton />}>
                <div className="group relative overflow-hidden rounded-lg">
                  <img
                    src={project.imgUrl}
                    width="400"
                    height="300"
                    alt={project.title}
                    className="h-full w-full object-cover transition-all duration-300 group-hover:scale-105"
                    style={{ aspectRatio: "400 / 300", objectFit: "cover" }}
                  />
                  <div className="absolute inset-0 flex flex-col items-start justify-end bg-gradient-to-t from-black/80 to-transparent p-6 transition-all duration-300 group-hover:bg-transparent">
                    <h3 className="text-xl font-bold text-white">{project.name}</h3>
                    <button
                      style={{ color: "white" }}
                      onClick={() => handleViewMore(project.uid)}
                      className="mt-4 inline-flex items-center gap-2 rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90 focus:outline-none focus:ring-1 focus:ring-ring"
                    >
                      Ver más
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-4 w-4"
                      >
                        <path d="M5 12h14"></path>
                        <path d="m12 5 7 7-7 7"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </LazyLoad>
            ))
          )}
        </div>
        <div className="flex justify-center mt-8">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handleClick(index + 1)}
              className={`px-4 py-2 mx-1 rounded-lg ${
                currentPage === index + 1 ? "bg-primary text-white" : "bg-gray-200 text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <Dialog open={isDialogOpen} handler={handleDialogClose} size="md">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <Typography variant="h5" color="blue-gray">
              {selectedProject?.name}
            </Typography>
            <button onClick={handleDialogClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <DialogBody divider>
            <Carousel loop={true} autoplay={true} className="rounded-xl h-80">
              {selectedProject?.images.map(({ url }, index) => (
                <div key={index} className="flex justify-center items-center h-full">
                  <img src={url} className="max-h-full max-w-full object-contain" alt="gallery-image" />
                </div>
              ))}
            </Carousel>
            <div className="grid gap-2 mt-2">
              <h2 className="text-1xl font-bold">Descripcion</h2>
              <div className="max-h-40 overflow-y-auto">
                <p className="text-sm text-muted-foreground leading-relaxed">{selectedProject?.description}</p>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleDialogClose} className="mr-1">
              Cerrar
            </Button>
          </DialogFooter>
        </div>
      </Dialog>
    </section>
  );
};

export default Projects;

import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Input,
  Chip,
  IconButton
} from "@material-tailwind/react";
import AxiosClient from "../../utils/axios";
import FeatherIcon from "feather-icons-react";
import { format } from 'date-fns';
import { AuthContext } from "../auth/authContext";
import SalesForm from "./SalesForm";
import Alert, { errorMsj, errorTitle } from "../../utils/alert";


const SalesScreen = () => {
  const { user } = useContext(AuthContext)
  const [sales, setSales] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const projectsPerPage = 5;

  const getSales = async () => {
    try {
      const response = await AxiosClient({
        url: '/house/',
        method: "GET",
        headers: { Authorization: `Bearer ${user?.token}` }
      });
      setSales(response.data);
    } catch (error) {
      Alert.fire({
        title: errorTitle,
        text: errorMsj,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
    })
    }
  }
  const changeStatus = async (id) => {
    try {
      const response = await AxiosClient({
        url: `/house/status/${id}`,
        method: "DELETE",
        headers: { Authorization: `Bearer ${user?.token}` }
      })
    } catch (error) {
      Alert.fire({
        title: errorTitle,
        text: errorMsj,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
    })
    } finally {
      getSales();
    }
  }
  const deleteSales = async (id) => {
    try {
      const response = await AxiosClient({
        url: `/house/${id}`,
        method: "DELETE",
        headers: { Authorization: `Bearer ${user?.token}` }
      })
    } catch (error) {
      Alert.fire({
        title: errorTitle,
        text: errorMsj,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
    })
    } finally {
      getSales();
    }
  }
  useEffect(() => {
    getSales();
  }, []);

  const renderStatusChip = (status) => {
    let color = 'green';
    let value = 'ACTIVO';
    if (status === false) {
      color = 'red';
      value = 'INACTIVO';
    } else if (status === true) {
      color = 'green';
      value = 'ACTIVO';
    }
    return (
      <Chip size="sm" className="w-20" variant="gradient" value={value} color={color}>
        {status}
      </Chip>
    );
  };

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;

  const filteredSales = sales.filter(sale =>
    sale.tittle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentSales = filteredSales.slice(indexOfFirstProject, indexOfLastProject);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Card className="w-full mt-5">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                Casas en venta
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                Detalles de los las propiedas en venta.
              </Typography>
            </div>
            <div className="flex w-full shrink-0 gap-2 md:w-max">
              <div className="w-full md:w-72">
                <Input
                  label="Buscar"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <Button className="flex items-center gap-3" color='blue' size="sm" onClick={() => setShowModal(true)}>
                Agregar nuevo
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Titulo
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Descripción
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Precio
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Area
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Construccion
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Location
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Banos
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Habitaciones
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Preview
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Fecha de registro
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Alberca
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Preventa
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Estado
                  </Typography>
                </th>
                <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Acciones
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentSales.map(({ uid, tittle, description, price, location, status, areaTotalSquareMeter, constructionSquareMeter, images, creationDate, presale, cantBedrooms, cantBathRooms, pool }) => (
                <tr key={uid}>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex items-center gap-3">
                      <Typography variant="small" color="blue-gray" className="font-bold">
                        {tittle}
                      </Typography>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {description}
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {" $ " + price}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {areaTotalSquareMeter + " m²"}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {constructionSquareMeter + " m²"}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {location}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {cantBathRooms}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal" style={{ maxHeight: '20vh', maxWidth: '50vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', }}>
                      {cantBedrooms}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <img src={images.length > 0 ? images[0].url : ''} className='h-10' alt={tittle} />
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {format(new Date(creationDate), "dd-MM-yyyy HH:mm:ss")}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                  <Chip size="sm" className="w-8" variant="gradient" value={pool ? ("SI") : ("NO")} color={pool ? ("green") : ("red")}  />
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                  <Chip size="sm" className="w-8" variant="gradient" value={presale ? ("SI") : ("NO")} color={presale ? ("green") : ("red")}  />
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    {renderStatusChip(status, uid)}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">

                    {status ? (
                      <Button disabled variant="filled" className="rounded-full" size="sm" color="amber" onClick={() => { setShowModalEdit(true); setSelected({ uid, tittle, description, images }) }}>
                        <FeatherIcon icon="edit" size={15} />
                      </Button>
                    ) : (
                      <Button variant="filled" className="rounded-full" size="sm" color="red" onClick={() => deleteSales(uid)}>
                        <FeatherIcon icon="trash" size={15} />
                      </Button>
                    )}
                    <Button variant="filled" className="rounded-full" size="sm" color={status ? ('red') : ('green')} onClick={() => changeStatus(uid)}>
                      <FeatherIcon icon={status ? ('x-circle') : ('check')} size={15} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <div className="flex items-center gap-2">
            {[...Array(Math.ceil(filteredSales.length / projectsPerPage)).keys()].map((number) => (
              <IconButton key={number + 1} variant={currentPage === number + 1 ? 'filled' : 'outlined'} size="sm" onClick={() => paginate(number + 1)}>
                {number + 1}
              </IconButton>
            ))}
          </div>
        </CardFooter>
      </Card>
      <SalesForm isOpen={showModal} onClose={() => setShowModal(false)} data={getSales} />
    </>
  );
}

export default SalesScreen;

import React, { useState } from "react";
import { Button, Input, Select, Option, Dialog, DialogHeader, DialogBody, DialogFooter, Alert } from "@material-tailwind/react";
import image_3 from '../../assets/3.jpg';
import AxiosClient from "../../utils/axios";
import AlertComponent from "../../utils/AlertCustom";
import { toBeRequired } from "@testing-library/jest-dom/matchers";

const CotizacionProyecto = () => {
  const [finishLevel, setFinishLevel] = useState("");
  const [alertState, setAlertState] = useState({});
  const [style, setStyle] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [size, setSize] = useState("");
  const [hasPool, setHasPool] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [budget, setBudget] = useState(null);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFinishLevelChange = (value) => setFinishLevel(value);
  const handleStyleChange = (value) => setStyle(value);
  const handleBathroomsChange = (value) => setBathrooms(value);
  const handleBedroomsChange = (value) => setBedrooms(value);
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const isNumeric = /^[0-9]*$/.test(value);

      if (isNumeric) {
        setContactInfo({
          ...contactInfo,
          [name]: value,
        });
        setErrorPhoneNumber('');
      } else {
        setErrorPhoneNumber('El teléfono debe contener solo números.');
      }
    } else {
      setContactInfo({
        ...contactInfo,
        [name]: value,
      });
    }
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      setError("Solo se permiten números.");
    } else if (parseInt(value, 10) < 160) {
      setError("El terreno debe ser mayor a 160 metros cuadrados.");
    } else if (parseInt(value, 10) > 5000) {
      setError("El terreno debe ser menor o igual a 5000 metros cuadrados.");
    } else {
      setError("");
    }
    setSize(value);
  };

  const handlePoolChange = (e) => setHasPool(e.target.checked);

  const calculateBudget = () => {
    const baseArea = 60;
    const bathroomsArea = bathrooms * 6;
    const bedroomsArea = bedrooms * 16;
    const totalArea = baseArea + bathroomsArea + bedroomsArea;

    let pricePerSquareMeter;
    if (finishLevel === "básico") {
      pricePerSquareMeter = 9000;
    } else if (finishLevel === "medio") {
      pricePerSquareMeter = 12000;
    } else {
      pricePerSquareMeter = 20000;
    }

    let styleMultiplier;
    if (style === "moderno" || style === "rústico") {
      styleMultiplier = 1.2;
    } else if (style === "colonial") {
      styleMultiplier = 1.4;
    } else {
      styleMultiplier = 1;
    }
    let baseCost = totalArea * pricePerSquareMeter;
    baseCost *= styleMultiplier;
    if (hasPool) {
      baseCost += 100000;
    }

    return baseCost.toFixed(2);
  };

  const formatPrice = (price) => {
    const number = Number(price);
    if (isNaN(number)) {
      return "";
    }
    return number.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN"
    });
  };

  const handleCalculate = () => {
    const validationErrors = {};
    if (!finishLevel) validationErrors.finishLevel = "Seleccione el nivel de acabado.";
    if (!style) validationErrors.style = "Seleccione el estilo.";
    if (!bathrooms) validationErrors.bathrooms = "Seleccione el número de baños.";
    if (!bedrooms) validationErrors.bedrooms = "Seleccione el número de dormitorios.";
    if (!size) validationErrors.size = "Ingrese el tamaño del terreno.";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    const budget = calculateBudget();
    setBudget(formatPrice(budget));
    setOpen(true);
  };
  const resetForm = () => {
    setFinishLevel("");
    setStyle("");
    setBathrooms("");
    setBedrooms("");
    setSize("");
    setHasPool(false);
    setContactInfo({
      name: "",
      email: "",
      phone: "",
    });
    setError("");
    setErrorPhoneNumber("");
  };
  
  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const response = await AxiosClient({
        url: "/lead/",
        method: "POST",
        data: JSON.stringify({
          name: contactInfo.name.toUpperCase(),
          email: contactInfo.email,
          phone: contactInfo.phone,
          status: true,
          constructionEstimate: {
            finishLevel: finishLevel.toUpperCase(),
            style: style.toUpperCase(),
            bathrooms: bathrooms,
            bedrooms: bedrooms,
            landSize: size,
            hasPool: hasPool
          }
        })
      })
      if (!response.error) {
        setAlertState({tittle:"Exito",type: "green", message:"Se han enviado tus datos correctamente, nuestro equipo se pondra en contacto contigo.", open:true })
        setOpen(false)
        resetForm()
      }
    } catch (error) {
      setAlertState({tittle:"Error!",type: "red", message:"Hemos tenido problemas en guardar tus datos. Por favor, intentalo de nuevo.", open:true })
      setOpen(false)
    } finally{
      setIsLoading(false)
    }
  }

  return (
    <section className="py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid gap-8 lg:grid-cols-2 lg:gap-12">
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Calculadora de Presupuesto de Construcción</h2>
            <p className="mt-4 text-muted-foreground md:text-xl">
              Complete los siguientes campos para obtener una estimación del presupuesto de construcción.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              <div className="grid gap-2">
                <Select label="Nivel de Acabado" id="finish-level" value={finishLevel} onChange={handleFinishLevelChange} error={!!errors.finishLevel}>
                  <Option value="básico">Básico</Option>
                  <Option value="medio">Intermedio</Option>
                  <Option value="alto">Premium</Option>
                </Select>
                {errors.finishLevel && <p className="text-red-500 text-sm mt-1">{errors.finishLevel}</p>}
              </div>
              <div className="grid gap-2">
                <Select label="Estilo" id="style" value={style} onChange={handleStyleChange} error={!!errors.style}>
                  <Option value="moderno">Minimalista</Option>
                  <Option value="colonial">Colonial</Option>
                  <Option value="rústico">Rústico</Option>
                </Select>
                {errors.style && <p className="text-red-500 text-sm mt-1">{errors.style}</p>}
              </div>
              <div className="grid gap-2">
                <Select
                  label="Baños"
                  id="bathrooms"
                  value={bathrooms}
                  onChange={handleBathroomsChange}
                  className="w-full"
                  error={!!errors.bathrooms}
                >
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                </Select>
                {errors.bathrooms && <p className="text-red-500 text-sm mt-1">{errors.bathrooms}</p>}
              </div>
              <div className="grid gap-2">
                <Select
                  label="Dormitorios"
                  id="bedrooms"
                  value={bedrooms}
                  onChange={handleBedroomsChange}
                  className="w-full"
                  error={!!errors.bedrooms}
                >
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                </Select>
                {errors.bedrooms && <p className="text-red-500 text-sm mt-1">{errors.bedrooms}</p>}
              </div>
              <div className="grid gap-2">
                <Input
                  label="Tamaño del terreno (m&sup2;)"
                  id="size"
                  value={size}
                  onChange={handleSizeChange}
                  error={!!error || !!errors.size}
                />
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                {errors.size && <p className="text-red-500 text-sm mt-1">{errors.size}</p>}
              </div>
              <div className="grid gap-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hasPool}
                    onChange={handlePoolChange}
                    className="form-checkbox"
                  />
                  <span>Incluir Alberca (+$100,000)</span>
                </label>
              </div>
            </div>
            <div className="mt-6 flex justify-center w-full">
              <Button onClick={handleCalculate} disabled={!finishLevel || !style || !bathrooms || !bedrooms || !size || error}>
                Calcular
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={image_3}
              width="400"
              height="400"
              alt="card-image"
              className="aspect-square overflow-hidden rounded-lg object-cover"
            />
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} size="md" className="bg-white p-6 rounded-lg shadow-lg">
        <DialogHeader>Estimación de Presupuesto</DialogHeader>
        <DialogBody>
          <p className="mb-4 text-gray-700">
            El presupuesto estimado es: <span className="text-2xl text-blue-500 font-bold">{budget} MXM</span>. Esto es un aproximado. Para conocer el costo exacto de su proyecto, ingrese sus datos y alguien de nuestro equipo se contactará con usted.
          </p>
          <div className="mt-4">
            <p className="font-semibold">Ingrese sus datos de contacto:</p>
            <div className="grid mt-2 gap-4">
              <Input
                label="Nombre"
                id="name"
                name="name"
                value={contactInfo.name}
                onChange={handleContactChange}
              />
              <Input
                label="Correo electrónico"
                id="email"
                name="email"
                type="email"
                value={contactInfo.email}
                onChange={handleContactChange}
              />
              <Input
                label="Teléfono"
                id="phone"
                name="phone"
                type="tel"
                value={contactInfo.phone}
                onChange={handleContactChange}
                error={!!errorPhoneNumber}
              />
              {errorPhoneNumber && <p className="text-red-500 text-sm mt-1">{errorPhoneNumber}</p>}
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => setOpen(false)} className="mr-2" color="red">
            Cancelar
          </Button>
          <Button onClick={() => handleSubmit()} color="green" loading={isLoading}>
            Enviar
          </Button>
        </DialogFooter>
      </Dialog>
      <AlertComponent title={alertState.tittle} type={alertState.type} message={alertState.message} open={alertState.open} onClose={()=>setAlertState({open:false})}/>
    </section>
  );
};

export default CotizacionProyecto;

import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Typography,
    Icon,
} from "@material-tailwind/react";
import { Navigate, useNavigate } from "react-router";
import CompanyLogo from "../assets/tabsLogo.png"; // Asegúrate de ajustar la ruta al logo de tu empresa

export default function Page404() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        const redirectTimer = setTimeout(() => {
            navigate("/");
        }, 5000);

        return () => {
            clearInterval(timer);
            clearTimeout(redirectTimer);
        };
    }, [navigate]);

    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-md text-center">
                <img
                    src={CompanyLogo}
                    alt="Company Logo"
                    className="h-40 w-40 mx-auto sm:float-none sm:mx-auto sm:mb-4 lg:float-left lg:mr-4"
                />
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">¡Oops, página no encontrada!</h1>
                <p className="mt-4 text-muted-foreground">
                    La página que buscas no existe. Puede haber sido movida o eliminada.
                </p>
                <p className="mt-4 text-muted-foreground">
                    Te redirigiremos al inicio en {countdown} segundos.
                </p>
                
            </div>
        </div>
    );
}

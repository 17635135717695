import React from "react";

const Skeleton = () => (
  <div className="animate-pulse flex flex-col items-center justify-center h-full w-full bg-gray-300 rounded-lg">
    <div className="h-64 w-full bg-gray-400"></div>
    <div className="h-6 w-3/4 bg-gray-400 mt-4"></div>
    <div className="h-6 w-1/2 bg-gray-400 mt-2"></div>
  </div>
);

export default Skeleton;

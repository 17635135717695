import React, { useContext, useState, useEffect } from "react";
import {
    Card,
    Typography,
    CardBody,
    CardFooter,
    Input,
    Textarea,
    Button,
    IconButton,
    Dialog,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import * as yup from "yup";
import AxiosClient from "../../utils/axios";
import { AuthContext } from "../auth/authContext";
import Alert, { errorMsj, errorTitle, succesMsj, successTitle } from "../../utils/alert";

export default function EditProjectForm({ isOpen, data, onClose, selected }) {
    const { user } = useContext(AuthContext);
    const [images, setImages] = useState([]);

    const handleClose = () => {
        formik.handleReset();
        setImages([]);
        onClose();
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const newImages = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (e) => {
                newImages.push({
                    fileBase64: e.target.result,
                    mimeType: file.type,
                });
                if (newImages.length === files.length) {
                    setImages((prevImages) => [...prevImages, ...newImages]);
                    formik.setFieldValue('images', [...formik.values.images, ...newImages]);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        formik.setFieldValue('images', formik.values.images.filter((_, i) => i !== index));
    };

    const formik = useFormik({
        initialValues: {
            uid: "",
            name: "",
            description: "",
            images: [],
        },
        validationSchema: yup.object().shape({
            name: yup.string().required("Campo obligatorio"),
            description: yup.string().required("Campo obligatorio"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await AxiosClient({
                    url: "/project/",
                    method: "PUT",
                    headers: { Authorization: `Bearer ${user.token}` },
                    data: JSON.stringify(values),
                });
                if (!response.error) {
                    Alert.fire({
                        title: successTitle,
                        text: succesMsj,
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Aceptar",
                    })
                }
            } catch (err) {
                Alert.fire({
                    title: errorTitle,
                    text: errorMsj,
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                })
            } finally {
                data();
                handleClose();
            }
        },
    });

    useEffect(() => {
        const { uid, name, description, images } = selected;
        formik.setFieldValue("uid", uid);
        formik.setFieldValue("name", name);
        formik.setFieldValue("description", description);
        if (images) {
            setImages(images.map(image => ({
                fileBase64: image.url,
                mimeType: image.mimeType,
            })));
            formik.setFieldValue("images", images);
        }
    }, [selected]);

    return (
        <Dialog open={isOpen} handler={handleClose} size="md" className="max-w-full">
            <form onSubmit={formik.handleSubmit}>
                <div className="flex items-center justify-between p-4">
                    <Typography variant="h5" color="blue-gray">
                        Editar proyecto
                    </Typography>
                    <IconButton variant="text" color="blue-gray" onClick={handleClose}>
                        <span className="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </IconButton>
                </div>
                <DialogBody divider className="overflow-y-auto" style={{maxHeight:"70vh"}}>
                    <Card className="w-full max-w-2xl">
                        <CardBody className="grid gap-6">
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Nombre</Typography>
                                <Input 
                                    id="name" 
                                    name="name"
                                    placeholder="Nombre del proyecto" 
                                    onChange={formik.handleChange} 
                                    value={formik.values.name} 
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <Typography variant="small" color="red">{formik.errors.name}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Descripción</Typography>
                                <Textarea 
                                    id="description" 
                                    name="description"
                                    placeholder="Describe tu proyecto" 
                                    className="min-h-[100px]"
                                    onChange={formik.handleChange} 
                                    value={formik.values.description} 
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <Typography variant="small" color="red">{formik.errors.description}</Typography>
                                )}
                            </div>
                            <div>
                                <Typography variant="small" color="gray">Imágenes</Typography>
                                <div className="grid grid-cols-3 gap-4 mt-2 max-h-80 overflow-y-auto">
                                    {images.map((image, index) => (
                                        <div key={index} className="relative group">
                                            <img
                                                src={image.fileBase64}
                                                alt={`Imagen ${index}`}
                                                className="aspect-square w-full rounded-md object-cover"
                                            />
                                            <IconButton
                                                variant="text"
                                                size="sm"
                                                className="absolute top-2 right-2 bg-background/80 hover:bg-background/90 text-red-500 group-hover:opacity-100 opacity-0"
                                                onClick={() => handleImageRemove(index)}
                                            >
                                                <TrashIcon className="w-4 h-4" />
                                                <span className="sr-only">Eliminar imagen</span>
                                            </IconButton>
                                        </div>
                                    ))}
                                    <div className="relative group">
                                        <Button
                                            variant="outlined"
                                            className="flex items-center justify-center w-full h-full rounded-md"
                                            component="label"
                                        >
                                            <PlusIcon className="w-6 h-6 text-muted-foreground" />
                                            <span className="sr-only">Agregar imagen</span>
                                            <input
                                                type="file"
                                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                multiple
                                                onChange={handleImageUpload}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </DialogBody>
                <DialogFooter>
                    <Button variant="outlined" onClick={handleClose} className="mr-2">Cancelar</Button>
                    <Button type="submit">Subir Proyecto</Button>
                </DialogFooter>
            </form>
        </Dialog>
    );
}

function PlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M5 12h14" />
            <path d="M12 5v14" />
        </svg>
    );
}

function TrashIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 6h18" />
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        </svg>
    );
}

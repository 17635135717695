import React, { useEffect, useState } from "react";
import { Dialog, DialogBody, DialogFooter, Button, Typography, Spinner, Carousel, Card, CardBody, IconButton } from "@material-tailwind/react";
import AxiosClient from "../../utils/axios";
import LazyLoad from "react-lazyload";
import Skeleton from "./Skeleton";
import FeatherIcon from "feather-icons-react";

const Purchase = () => {
    const [purchases, setPurchases] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogLoading, setIsDialogLoading] = useState(false);
    const purchasesPerPage = 8;
    const [active, setActive] = useState("");

    const whatsappNumber = '+527774948489';
    const whatsappMessage = `Hola, estoy interesado en la propiedad ${selectedPurchase?.tittle}. ¿Podríamos hablar sobre ella?`;

    const getPurchases = async () => {
        setIsLoading(true);
        try {
            const [housesResponse, landsResponse] = await Promise.all([
                fetch('https://tabsarq.mx:8443/house/get-status/true', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }),
                fetch('https://tabsarq.mx:8443/land/get-status/true', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            ]);
            const housesData = await housesResponse.json();
            const landsData = await landsResponse.json();
            setPurchases([...housesData.data, ...landsData.data]);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        getPurchases();
    }, []);

    const indexOfLastPurchase = currentPage * purchasesPerPage;
    const indexOfFirstPurchase = indexOfLastPurchase - purchasesPerPage;
    const currentPurchases = purchases.slice(indexOfFirstPurchase, indexOfLastPurchase);

    const totalPages = Math.ceil(purchases.length / purchasesPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleViewMore = (purchase) => {
        setSelectedPurchase(purchase)
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setActive("");
        setSelectedPurchase(null);
        setIsDialogOpen(false);
    };

    const structurePrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <section className="w-full py-12 md:py-24 lg:py-32">
            <div className="container mx-auto px-4 md:px-6">
                <div className="text-center max-w-lg mx-auto space-y-4">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Propiedades en Venta</h2>
                    <p className="max-w-md text-muted-foreground mx-auto md:text-xl lg:text-base xl:text-xl">
                        Descubre nuestras propiedades más destacadas y encuentra el hogar de tus sueños.
                    </p>
                </div>
                <div className="mt-12 grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {isLoading ? (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                            <Spinner className="h-16 w-16 text-gray-900/50" />
                        </div>
                    ) : (
                        currentPurchases.map((purchase, index) => (
                            <LazyLoad key={index} height={200} offset={100} placeholder={<Skeleton />}>
                                <Card className="relative w-full max-w-md bg-card text-card-foreground shadow-lg rounded-lg overflow-hidden">
                                    {purchase.presale && (
                                        <div className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
                                            Pre-venta
                                        </div>
                                    )}
                                    <img
                                        src={purchase.images[0].url}
                                        alt={purchase.tittle}
                                        width={400}
                                        height={240}
                                        className="w-full h-60 object-cover"
                                        style={{ aspectRatio: "400 / 300", objectFit: "cover" }}
                                    />
                                    <CardBody className="p-6">
                                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                                            <h3 className="text-lg sm:text-lg font-bold">{purchase.tittle}</h3>
                                            <span className="text-xl sm:text-lg font-bold text-primary">${structurePrice(purchase?.price) || ""}</span>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 mb-6">
                                            <div className="flex items-center gap-2">
                                                <RulerIcon className="w-5 h-5 text-muted-foreground" />
                                                <span className="text-sm sm:text-base">{purchase.areaTotalSquareMeter} m²</span>
                                            </div>
                                            {purchase.constructionSquareMeter && (
                                                <div className="flex items-center gap-2">
                                                    <FeatherIcon icon="home" size={18} />
                                                    <span className="text-sm sm:text-base">{purchase.constructionSquareMeter} m²</span>
                                                </div>
                                            )}
                                            {purchase.cantBedrooms && (
                                                <div className="flex items-center gap-2">
                                                    <BedIcon className="w-5 h-5 text-muted-foreground" />
                                                    <span className="text-sm sm:text-base">{purchase.cantBedrooms} Hab.</span>
                                                </div>
                                            )}
                                            {purchase.cantBathRooms && (
                                                <div className="flex items-center gap-2">
                                                    <BathIcon className="w-5 h-5 text-muted-foreground" />
                                                    <span className="text-sm sm:text-base">{purchase.cantBathRooms} Baños</span>
                                                </div>
                                            )}
                                            <div className="flex items-center gap-2">
                                                <LocationIcon className="w-5 h-5 text-muted-foreground" />
                                                <span className="text-sm sm:text-sm">{purchase.location}</span>
                                            </div>

                                        </div>
                                        <Button onClick={() => handleViewMore(purchase)} className="w-full">Ver detalles</Button>
                                    </CardBody>
                                </Card>
                            </LazyLoad>
                        ))
                    )}
                </div>
                <div className="flex justify-center mt-8">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handleClick(index + 1)}
                            className={`px-4 py-2 mx-1 rounded-lg ${currentPage === index + 1 ? "bg-primary text-white" : "bg-gray-200 text-gray-700"
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>

            <Dialog
                open={isDialogOpen}
                handler={handleDialogClose}
                size="sm"
                className="p-0 max-w-full sm:h-[80vh] h-[90vh]">
                <div className="flex flex-col h-full">
                    <div className="flex items-center justify-between p-4">
                        <Typography variant="h5" color="blue-gray">
                            {selectedPurchase?.tittle}
                        </Typography>
                        <button onClick={handleDialogClose} className="text-gray-500 hover:text-gray-700">
                            <XIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <DialogBody divider className="flex-grow overflow-y-auto">
                        <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} className="rounded-xl h-80">
                            {selectedPurchase?.images.map(({ url }, index) => (
                                <div key={index} className="flex justify-center items-center h-full">
                                    <img src={url} className="max-h-full max-w-full object-contain" alt={`gallery-image-${index}`} />
                                </div>
                            ))}
                        </Carousel>
                        <div className="grid gap-4 mt-4">
                            <h2 className="text-2xl font-bold text-gray-800">{selectedPurchase?.tittle}</h2>
                            <div className="text-gray-600">
                                <p className="text-sm leading-relaxed">{selectedPurchase?.description}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-2">
                                <div>
                                    <h3 className="text-lg font-medium text-gray-800">Precio:</h3>
                                    <p className="text-gray-600">${structurePrice(selectedPurchase?.price || "")} MXN</p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-medium text-gray-800">Ubicación:</h3>
                                    <p className="text-gray-600">{selectedPurchase?.location}</p>
                                </div>
                                {selectedPurchase?.constructionSquareMeter && (
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-800">Área Construcción:</h3>
                                        <p className="text-gray-600">{selectedPurchase?.constructionSquareMeter} m²</p>
                                    </div>
                                )}
                                <div>
                                    <h3 className="text-lg font-medium text-gray-800">Área total:</h3>
                                    <p className="text-gray-600">{selectedPurchase?.areaTotalSquareMeter}m²</p>
                                </div>
                                {selectedPurchase?.cantBedrooms && (
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-800">Habitaciones:</h3>
                                        <p className="text-gray-600">{selectedPurchase?.cantBedrooms}</p>
                                    </div>
                                )}
                                {selectedPurchase?.cantBathRooms && (
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-800">Baños:</h3>
                                        <p className="text-gray-600">{selectedPurchase?.cantBathRooms}</p>
                                    </div>
                                )}
                                {selectedPurchase?.pool && (
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-800">Piscina:</h3>
                                        <p className="text-gray-600">{selectedPurchase?.pool ? "Sí" : "No"}</p>
                                    </div>
                                )}
                                {selectedPurchase?.areaService && (
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-800">Área de Servicio:</h3>
                                        <p className="text-gray-600">{selectedPurchase?.areaService}</p>
                                    </div>
                                )}
                                {selectedPurchase?.soilType && (
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-800">Tipo de Suelo:</h3>
                                        <p className="text-gray-600">{selectedPurchase?.soilType}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </DialogBody>
                    <DialogFooter className="flex justify-between p-4 lg:mb-1 mb-10">
                        <Button variant="text" color="blue-gray" onClick={handleDialogClose} className="mr-1">
                            Cerrar
                        </Button>
                        <Button
                            variant="text"
                            color="green"
                            onClick={() => window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`, '_blank')}
                            className="mr-2"
                        >
                            Contactar WhatsApp
                        </Button>
                    </DialogFooter>
                </div>
            </Dialog>
        </section >
    );
};

function BathIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M9 6 6.5 3.5a1.5 1.5 0 0 0-1-.5C4.683 3 4 3.683 4 4.5V17a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5" />
            <line x1="10" x2="8" y1="5" y2="7" />
            <line x1="2" x2="22" y1="12" y2="12" />
            <path d="M7 19v2" />
            <path d="M17 19v2" />
            <path d="M10 15v.01" />
            <path d="M14 15v.01" />
        </svg>
    );
}

function BedIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M2 12H22" />
            <path d="M2 6H22" />
            <path d="M2 18V6" />
            <path d="M22 18V6" />
            <path d="M7 18V22" />
            <path d="M17 18V22" />
        </svg>
    );
}

function RulerIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M4 10 10 4 20 14 14 20 4 10" />
            <path d="M7.5 7.5 6 6" />
            <path d="M13.5 13.5 12 12" />
            <path d="M10.5 10.5 9 9" />
            <path d="M16.5 16.5 15 15" />
        </svg>
    );
}

function XIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
    );
}
function LocationIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M21 10c0 6-9 12-9 12S3 16 3 10a9 9 0 1 1 18 0Z" />
            <circle cx="12" cy="10" r="3" />
        </svg>
    );
}

export default Purchase;

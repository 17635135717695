import { Carousel, Typography, Button } from "@material-tailwind/react";
import img1 from "../assets/1.jpg"
import img2 from "../assets/2.jpg"
import img3 from "../assets/3.jpg"
export function CarouselCustom() {
  return (
    <Carousel >
      <div className="relative h-full w-full">
        <img
          src={img1}
          alt="image 1"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl"
            >
              Bienvenido a Tabs Arq
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80"
            >
              Estamos convencidos que somos capaces de resolver tus problemas arquitectonicos
            </Typography>
            <div className="hidden sm:flex flex-col items-center gap-2 sm:flex-row sm:justify-center">
              <Button
                size="lg"
                color="white"
                onClick={() => window.location.href = '/projects'}
              >
                Ver proyectos
              </Button>
              <Button
                size="lg"
                color="white"
                variant="text"
                onClick={() => window.location.href = '/calculator'}
              >
                Obtener una cotización
              </Button>
            </div>
          </div>
        </div>
      </div>
      <img
        src={img2}
        alt="image 2"
        className="h-full w-full object-cover"
      />
      <img
        src={img3}
        alt="image 3"
        className="h-full w-full object-cover"
      />
      
    </Carousel>
  );
}
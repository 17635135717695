import React, { useContext, useState } from "react";
import {
    Card,
    Typography,
    CardBody,
    CardFooter,
    Input,
    Textarea,
    Button,
    IconButton,
    Dialog,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import * as yup from "yup";
import AxiosClient from "../../../utils/axios";
import { AuthContext } from "../../auth/authContext";
import FeatherIcon from "feather-icons-react";
import Alert, { errorMsj, errorTitle, succesMsj, successTitle } from "../../../utils/alert";

export default function LandForm({ isOpen, data, onClose }) {
    const { user } = useContext(AuthContext);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        formik.handleReset();
        setImages([]);
        onClose();
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const newImages = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (e) => {
                newImages.push({
                    fileBase64: e.target.result,
                    mimeType: file.type,
                });
                if (newImages.length === files.length) {
                    setImages((prevImages) => [...prevImages, ...newImages]);
                    formik.setFieldValue('images', [...formik.values.images, ...newImages]);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        formik.setFieldValue('images', formik.values.images.filter((_, i) => i !== index));
    };

    const formik = useFormik({
        initialValues: {
            tittle: "",
            description: "",
            price: 0,
            location: "",
            areaTotalSquareMeter: null,
            images: [],
            presale: false,
            areaService: "",
            soilType: "",
            status: false,
        },
        validationSchema: yup.object().shape({
            tittle: yup.string().required("Campo obligatorio"),
            description: yup.string().required("Campo obligatorio"),
            price: yup.number().required("Campo obligatorio").min(0, "El precio no puede ser negativo"),
            location: yup.string().required("Campo obligatorio"),
            areaTotalSquareMeter: yup.number().required("Campo obligatorio").min(0, "El área total no puede ser negativa"),
            areaService: yup.string().required("Campo obligatorio"),
            soilType: yup.string().required("Campo obligatorio"),
        }),
        onSubmit: async (values) => {
            setIsLoading(true)
            try {
                const response = await AxiosClient({
                    url: "/land/save/",
                    method: "POST",
                    headers: { Authorization: `Bearer ${user.token}` },
                    data: JSON.stringify(values),
                });
                if (!response.error) {
                    Alert.fire({
                        title: successTitle,
                        text: succesMsj,
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Aceptar",
                    })
                }
            } catch (err) {
                Alert.fire({
                    title: errorTitle,
                    text: errorMsj,
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                })
            } finally {
                data();
                handleClose();
                setIsLoading(false)
            }
        },
    });

    return (
        <Dialog open={isOpen} handler={handleClose} size="lg" className="max-w-full h-[80vh]">
            <form onSubmit={formik.handleSubmit} className="h-full flex flex-col">
                <div className="flex items-center justify-between p-4">
                    <Typography variant="h5" color="blue-gray">
                        Subir Terreno
                    </Typography>
                    <IconButton variant="text" color="blue-gray" onClick={handleClose}>
                        <span className="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </IconButton>
                </div>
                <DialogBody divider className="flex-1 overflow-y-auto p-4">
                    <Card className="w-full max-w-4xl mx-auto">
                        <CardBody className="grid grid-cols-2 gap-6">
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Título</Typography>
                                <Input
                                    id="tittle"
                                    name="tittle"
                                    placeholder="Título del terreno"
                                    onChange={formik.handleChange}
                                    value={formik.values.tittle}
                                    error={formik.touched.tittle && Boolean(formik.errors.tittle)}
                                />
                                {formik.touched.tittle && formik.errors.tittle && (
                                    <Typography variant="small" color="red">{formik.errors.tittle}</Typography>
                                )}
                            </div>
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Descripción</Typography>
                                <Textarea
                                    id="description"
                                    name="description"
                                    placeholder="Describe el terreno"
                                    className="min-h-[100px]"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <Typography variant="small" color="red">{formik.errors.description}</Typography>
                                )}
                            </div>
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Ubicación</Typography>
                                <Input
                                    id="location"
                                    name="location"
                                    placeholder="Ubicación del terreno"
                                    onChange={formik.handleChange}
                                    value={formik.values.location}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                />
                                {formik.touched.location && formik.errors.location && (
                                    <Typography variant="small" color="red">{formik.errors.location}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Precio</Typography>
                                <Input
                                    id="price"
                                    name="price"
                                    type="number"
                                    placeholder="Precio del terreno"
                                    onChange={formik.handleChange}
                                    value={formik.values.price}
                                    error={formik.touched.price && Boolean(formik.errors.price)}
                                />
                                {formik.touched.price && formik.errors.price && (
                                    <Typography variant="small" color="red">{formik.errors.price}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">PreVenta</Typography>
                                <input
                                    type="checkbox"
                                    id="presale"
                                    name="presale"
                                    checked={formik.values.presale}
                                    onChange={formik.handleChange}
                                    className="form-checkbox"
                                />
                                <span>&nbsp;Marcar si es preventa</span>
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Área Total (m²)</Typography>
                                <Input
                                    id="areaTotalSquareMeter"
                                    name="areaTotalSquareMeter"
                                    type="number"
                                    placeholder="Área total en metros cuadrados"
                                    onChange={formik.handleChange}
                                    value={formik.values.areaTotalSquareMeter}
                                    error={formik.touched.areaTotalSquareMeter && Boolean(formik.errors.areaTotalSquareMeter)}
                                />
                                {formik.touched.areaTotalSquareMeter && formik.errors.areaTotalSquareMeter && (
                                    <Typography variant="small" color="red">{formik.errors.areaTotalSquareMeter}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Área de Servicios</Typography>
                                <Input
                                    id="areaService"
                                    name="areaService"
                                    type="text"
                                    placeholder="Área de servicios"
                                    onChange={formik.handleChange}
                                    value={formik.values.areaService}
                                    error={formik.touched.areaService && Boolean(formik.errors.areaService)}
                                />
                                {formik.touched.areaService && formik.errors.areaService && (
                                    <Typography variant="small" color="red">{formik.errors.areaService}</Typography>
                                )}
                            </div>
                            <div className="space-y-2">
                                <Typography variant="small" color="gray">Tipo de Suelo</Typography>
                                <Input
                                    id="soilType"
                                    name="soilType"
                                    type="text"
                                    placeholder="Tipo de suelo"
                                    onChange={formik.handleChange}
                                    value={formik.values.soilType}
                                    error={formik.touched.soilType && Boolean(formik.errors.soilType)}
                                />
                                {formik.touched.soilType && formik.errors.soilType && (
                                    <Typography variant="small" color="red">{formik.errors.soilType}</Typography>
                                )}
                            </div>
                            <div className="col-span-2 space-y-2">
                                <Typography variant="small" color="gray">Imágenes</Typography>
                                <input
                                    type="file"
                                    id="images"
                                    name="images"
                                    multiple
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                <div className="grid grid-cols-3 gap-4 mt-4">
                                    {images.map((image, index) => (
                                        <div key={index} className="relative">
                                            <img src={image.fileBase64} alt={`uploaded-${index}`} className="object-cover w-full h-32 rounded-lg" />
                                            <Button
                                                size="sm"
                                                color="red"
                                                className="absolute top-1 right-1"
                                                onClick={() => handleImageRemove(index)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </DialogBody>
                <DialogFooter>
                    <Button variant="outlined" onClick={handleClose} className="mr-2">Cancelar</Button>
                    {isLoading ? (
                        <Button variant="submit" loading={true}>
                            Subiendo...
                        </Button>
                    ) : (<Button type="submit" color="green">Subir</Button>
                    )}
                </DialogFooter>
            </form>
        </Dialog>
    );
}

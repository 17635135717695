import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { calcLength, motion } from "framer-motion";
import imgDiseñoInterior from '../../assets/diseño-interiores.JPG';
import imgCasaHabitacion from '../../assets/casa-habitacion.JPG';
import imgResidencial from '../../assets/residencial.JPG';
import { CarouselCustom } from "../../components/CarouselCustom";
import AxiosClient from "../../utils/axios";
import { Spinner } from "@material-tailwind/react";

const Home = () => {
  return (
    <div>
      <div className="mt-20 lg:mt-0">
        <CarouselCustom />
        <MainContent />
      </div>
    </div>
  );
};

function MainContent() {
  return (
    <main className="flex-1 bg-gray-100">
      <Section className="bg-white">
        <WelcomeSection />
      </Section>
      <Section className="bg-gray-100">
        <FeaturesSection />
      </Section>
      <Section className="bg-white">
        <ProjectsSection />
      </Section>
    </main>
  );
}

function Section({ className, children }) {
  return <section className={className}>{children}</section>;
}

function WelcomeSection() {
  return (
    <div className="w-full py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 md:px-6 space-y-12 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl">De una idea a la realidad</h1>
          <p className="max-w-3xl text-gray-700 md:text-xl lg:text-lg xl:text-xl">
            En nuestro estudio de arquitectura, transformamos tus ideas en espacios únicos y funcionales. Con una combinación de creatividad y precisión técnica, nos dedicamos a diseñar proyectos que reflejan tus necesidades y aspiraciones. Desde la concepción inicial hasta la ejecución final, estamos contigo en cada paso del camino para asegurar que tu visión se haga realidad.
          </p>
        </div>
        <ProjectsPreview />
      </div>
    </div>
  );
}

function ProjectsPreview() {
  const projects = [
    { title: "Casa Habitación", description: "Espacio residencial que combina comodidad y elegancia, diseñado para satisfacer tus necesidades diarias con un toque de sofisticación.", img: imgCasaHabitacion },
    { title: "Diseño de Interiores", description: "Ambientes interiores personalizados que reflejan tu estilo único y optimizan el uso del espacio, creando un entorno funcional y estéticamente atractivo.", img: imgDiseñoInterior },
    { title: "Residencial", description: "Desarrollo residencial que ofrece una vida contemporánea y confortable, con detalles arquitectónicos que realzan la calidad de vida y el bienestar de sus habitantes.", img: imgResidencial }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {projects.map((project, index) => (
        <motion.div
          key={index}
          className="bg-white rounded-lg shadow-md overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true, amount: 0.8 }}
        >
          <img src={project.img} alt={`Project ${index + 1}`} className="w-full h-48 object-cover" />
          <div className="p-4">
            <h3 className="text-xl font-bold">{project.title}</h3>
            <p className="text-gray-700">{project.description}</p>
            <div className="flex justify-end mt-4">
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}

function FeaturesSection() {
  const features = [
    { title: "Trayectoria", description: "Con décadas de experiencia, nuestra trayectoria demuestra nuestro compromiso con la excelencia en cada proyecto.", Icon: CompassIcon },
    { title: "Innovación", description: "Nos destacamos por nuestra capacidad de innovar, utilizando las últimas tecnologías y técnicas de diseño para superar las expectativas.", Icon: BriefcaseIcon },
    { title: "Inspiración", description: "Nos inspiramos en tus sueños y aspiraciones para crear espacios que no solo funcionen bien, sino que también te llenen de emoción y satisfacción.", Icon: BoltIcon }
  ];

  return (
    <div className="w-full py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 md:px-6 space-y-12 text-center">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">¿Por qué elegirnos?</h2>
          <p className="max-w-3xl text-gray-700 md:text-xl lg:text-lg xl:text-xl">
            Ofrecemos una amplia gama de servicios arquitectónicos para ayudarte a hacer realidad tu visión.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-md p-6"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true, amount: 0.8 }}
            >
              <feature.Icon className="h-8 w-8 text-blue-500" />
              <h3 className="text-xl font-bold mt-4">{feature.title}</h3>
              <p className="text-gray-700 mt-2">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ProjectsSection() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjects = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://tabsarq.mx:8443/project/get-status/true', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setProjects(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="w-full py-12 md:py-24 lg:py-32 ">
      <div className="container mx-auto px-4 md:px-6 space-y-12 text-center">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Nuestros últimos proyectos</h2>
          <p className="max-w-3xl text-gray-700 md:text-xl lg:text-lg xl:text-xl">
            Vea algunos de nuestros proyectos más recientes e impresionantes.
          </p>
        </div>
        {loading ? (
          <div className="flex justify-center">
            <Spinner className="h-12 w-12 text-blue-500" />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {projects.slice(0, 3).map((project, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true, amount: 0.8 }}
              >
                <img src={project.images[0].url} alt={`Project ${index + 1}`} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h3 className="text-xl font-bold">{project.name}</h3>
                  <p className="text-gray-700">
                    {project.description.length > 100
                      ? `${project.description.substring(0, 100)}...`
                      : project.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
// Icons (example of functional components)
function MountainIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M14.707 13.293L10 8.586 5.293 13.293 4 12l6-6 6 6-1.293 1.293z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function CompassIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13h-2v5h5v-2h-3V5z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function BriefcaseIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M6 2a2 2 0 00-2 2v2H3a2 2 0 00-2 2v8a2 2 0 002 2h14a2 2 0 002-2v-8a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H6zM5 4a1 1 0 011-1h8a1 1 0 011 1v2H5V4zm2 9h6v1H7v-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function BoltIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M11 3a1 1 0 00-1.923-.615L5.74 10H3a1 1 0 00-.923 1.617l6 8a1 1 0 001.846-.616l-1.334-4.667L12.26 12H15a1 1 0 00.923-1.617l-6-8z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default Home;

import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "../../modules/auth/Login";
import PrivateRoute from "./PrivateRoutes";
import Home from "../../modules/landingPage/Home";
import HeaderBar from "../HeaderBar";
import Layout from "../../components/Layout";
import Projects from "../../modules/landingPage/Projects";
import AboutUs from "../../modules/landingPage/AboutUs";
import CalculatorProject from "../../modules/calculator/CalculatorProject";
import Dashboard from "../../modules/dashboard/Dashboard";
import Purchase from "../../modules/landingPage/Purchase";
import Page404 from "../../templates/404";
const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout><Home/></Layout>} />
        <Route path="/projects" element={<Layout> <Projects/></Layout>}/>
        <Route path="/about-us" element={<Layout><AboutUs/></Layout>}/>
        <Route path="/purchase" element={<Layout><Purchase/></Layout>}/>
        <Route path="/calculator" element={<Layout><CalculatorProject/></Layout>}/>
        <Route path="/dashboard" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path="*" element={<Page404/>} />
      </Routes>
    </Router>
  )
}

export default AppRouter;
import React from 'react';
import { Alert, Button, Typography } from "@material-tailwind/react";

const AlertComponent = ({ type, title, message, open, onClose }) => {
  if (!open) return null;

  return (
    <div className="fixed top-5 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md">
      <Alert color={type} show={open} onClose={() => onClose()} >
        <Typography variant="h5" color="white">
          {title}
        </Typography>
        <Typography color="white" className="mt-2 font-normal">
          {message}
        </Typography>
      </Alert>
    </div>
  );
};

export default AlertComponent;

import React, { useContext} from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../modules/auth/authContext";

const PrivateRoute = ({ children }) => {
    const { user } = useContext(AuthContext);
  
    return user.isLogged ? children : <Navigate to="/login" />;
  };
  
  export default PrivateRoute;
import React from "react";
import tabsLogo from '../../assets/tabsLogo.png'
import aboutUs1 from '../../assets/aboutus_1.jpg'
import aboutUs2 from '../../assets/aboutus_2.jpg'
import aboutUs3 from '../../assets/aboutUS_3.JPG'

const AboutSection = () => {
  return (
    <section className="py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid gap-8 lg:grid-cols-2 lg:gap-12">
          <div>
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Sobre Nosotros</h2>
            <p className="mt-4 text-muted-foreground md:text-xl">
              Con más de 20 años de trayectoria en el campo de la arquitectura, nos apasiona la arquitectura y
              el arte que esta representa. Nos enfocamos en la innovación y en diseñar a partir de las necesidades
              del cliente para garantizar su comodidad y satisfacción. Creemos que cada proyecto es una oportunidad
              para crear espacios únicos que reflejen la visión y los sueños de nuestros clientes. Nuestros valores
              fundamentales de integridad y respeto nos guían en cada proyecto, manteniendo una comunicación honesta y
              transparente con nuestros clientes, colaboradores y comunidades.
            </p>
            <p className="mt-4 text-muted-foreground md:text-xl">
              Adoptamos prácticas sostenibles y responsables en todas las etapas de nuestro trabajo,
              asegurando que nuestras construcciones sean tanto estéticamente agradables como ecológicamente
              responsables. Gracias a nuestro compromiso y dedicación, hemos logrado construir una sólida
              reputación en la industria, convirtiéndonos en sinónimo de calidad, confianza y excelencia.

            </p>
          </div>
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <img
                src={tabsLogo}
                width="300"
                height="300"
                alt="ilustrativa1"
                className="aspect-square overflow-hidden rounded-lg object-cover"
              />
              <img
                src={aboutUs1}
                width="300"
                height="300"
                alt="ilustrativa 2"
                className="aspect-square overflow-hidden rounded-lg object-cover"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <img
                src={aboutUs2}
                width="300"
                height="300"
                alt="ilustrativa 3"
                className="aspect-square overflow-hidden rounded-lg object-cover"
              />
              <img
                src={aboutUs3}
                width="300"
                height="300"
                alt="ilustrativa 4"
                className="aspect-square overflow-hidden rounded-lg object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FeaturedProjectsSection = () => {
  return (
    <section className="w-full bg-muted py-12 md:py-24 lg:py-32" style={{ backgroundColor: '#f2f2f2' }}>
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid gap-8 lg:grid-cols-2 lg:gap-12">

          <div className="space-y-1">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Contáctanos</h2>
            <p className="text-muted-foreground md:text-xl">
              Si tienes alguna consulta o deseas más información sobre nuestros servicios, no dudes en ponerte en
              contacto con nosotros.
            </p>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-5 w-5 text-muted-foreground"
                >
                  <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                  <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                </svg>
                <span>info@tabsarq.mx</span>
              </div>
              <div className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-5 w-5 text-muted-foreground"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20"></path>
                  <path d="M2 12h20"></path>
                </svg>
                <span>www.tabsarq.mx</span>
              </div>
              <div className="flex items-center gap-2">
                <a className="text-muted-foreground hover:text-primary" href="https://www.instagram.com/tabs.arq">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-6 w-6"
                  >
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>

                  </svg>
                </a>
                <span>@tabs.arq</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutUs = () => {
  return (
    <div className="w-full">
      <AboutSection />
      <FeaturedProjectsSection />
    </div>
  );
};

export default AboutUs;
